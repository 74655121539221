import React from 'react';
import Layout from '../components/layout';
import { Container, Anchor, Section } from '../components/ui';

export default function NotFound(): JSX.Element {
  return (
    <Layout title="404: Page not found">
      <Section className="bg-hero">
        <Container py>
          <div className="text-center">
            <i className="fa-light fa-smile text-primary text-[200px] pb-[50px]" />
            <h1 className="font-thin text-[100px] leading-none">404</h1>
            <h2 className="text-primary">Page not found</h2>
            <div className="mt-5">
              <p>Sorry! We could not find the page you were looking for.</p>
              <p>
                Please go{' '}
                <Anchor to="/">
                  <span className="underline text-primary">back to our homepage</span>
                </Anchor>{' '}
                and try again.
              </p>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
}
